#header {
    font-size: 20px;
    /*font-family: Roboto;*/
    font-weight: 400;
    color: #393C44;
}
#subnav {
    /*border-bottom: 1px solid #E8E8EF;*/
    /*width: calc(100% + 20px);*/
    width: 100%;
    /*margin-left: -20px;*/
    /*margin-top: 10px;*/
}
.navdisabled {
    transition: all .3s cubic-bezier(.05,.03,.35,1);
}
.navdisabled:hover {
    cursor: pointer;
    opacity: .5;
}
.navactive {
    position: relative;
    color: #393C44!important;
}
.navactive:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6px;
    display: block;
    content: "";
    width:  100%;
    height: 2px;
    background-color: #217CE8;
    /*margin-top: -4px;*/
}
#actions {
    /*display: inline-block;*/
    /*font-family: Roboto;*/
    font-weight: 500;
    color: #808292;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    width: calc(100% / 2);
    text-align: center;
    /*float: left;*/
}
#loggers {
    width: calc(100% / 2);
    /*display: inline-block;*/
    /*font-family: Roboto;*/
    font-weight: 500;
    color: #808292;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    text-align: center;
}
#footer {
    position: absolute;
    left: 0;
    /*padding-left: 20px;*/
    line-height: 20px;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #E8E8EF;
    height: 50px;
    box-sizing: border-box;
    background-color: #FFF;
    /*font-family: Roboto;*/
    /*font-size: 14px;*/
}
.addBtn {
    padding: 2px 15px;
    cursor: pointer;
}
.addBtn:hover {
    background-color: #f0f3f9;
}

.blockelem:first-child {
    margin-top: 20px
}
.blockelem {
    padding-top: 10px;
    /*width: 318px;*/
    width: 100%;
    border: 1px solid transparent;
    transition-property: box-shadow, height;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.05,.03,.35,1);
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(22, 33, 74, 0);
    box-sizing: border-box;
}
.blockelem:hover {
    box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.08);
    border-radius: 5px;
    background-color: #FFF;
    cursor: pointer;
}
.grabme, .blockico {
    display: inline-block;
}
.grabme {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: -14px;
    width: 15px;
}
#blocklist {
    height: calc(100% - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}
.blockin {
    display: inline-block;
    vertical-align: top;
    /*margin-left: 12px;*/
}
.blocktext {
    display: inline-block;
    /*width: 220px;*/
    vertical-align: top;
    margin-left: 12px
}
.blocktitle {
    margin: 0px!important;
    padding: 0px!important;
    /*font-family: Roboto;*/
    font-weight: 500;
    font-size: 16px;
    color: #393C44;
}
.blockdesc {
    margin-top: 5px;
    /*font-family: Roboto;*/
    color: #808292;
    font-size: 14px;
    line-height: 21px;
}

/*.block {*/
    /*background-color: #FFF;*/
    /*margin-top: 0px!important;*/
    /*box-shadow: 0px 4px 30px rgba(22, 33, 74, 0.05);*/
/*}*/

/*DND*/
.dndflow {
    position: relative;
    flex-direction: column;
    display: flex;
    height: 100%;
}

.dndflow .propWrap {
    position: absolute;
    right: 0;
    padding: 1rem;
    bottom: 0;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #FFF;
    border: 1px solid #E8E8EF;
    box-sizing: border-box;
}

.dndflow .propWrap .closeProps {
    position: absolute;
    left: -20px;
    top: 0;
    display: block;
    width:  20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
}

.dndflow .propWrap .closeProps:hover {
    font-weight: 900;
    font-size: 22px;
}

.dndflow .propWrap .select-wrapper .dropdown-content {
    left: 0 !important;
}

.dndflow aside {
    position: relative;
    /*border-right: 1px solid #E8E8EF;*/
    padding: 15px 10px;
    font-size: 12px;
    /*background: #fcfcfc;*/

    width: 363px;
    background-color: #FFF;
    border: 1px solid #E8E8EF;
    box-sizing: border-box;
    height: 100%;

}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    /*height: 20px;*/
    /*padding: 4px;*/
    /*border: 1px solid #1a192b;*/
    /*border-radius: 2px;*/
    /*margin-bottom: 10px;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

#search input {
    width: 318px;
    /*height: 40px;*/
    /*background-color: #FFF;*/
    /*border: 1px solid #E8E8EF;*/
    /*box-sizing: border-box;*/
    /*box-shadow: 0px 2px 8px rgba(34,34,87,0.05);*/
    /*border-radius: 5px;*/
    /*text-indent: 35px;*/
    /*font-family: Roboto;*/
    font-size: 14px;
}
::-webkit-input-placeholder { /* Edge */
    color: #C9C9D5;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C9C9D5
}

::placeholder {
    color: #C9C9D5;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 300px;
    }
}