.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mt-0 {
    margin-top: 0rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-5 {
    margin-top: 5rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 2rem;
}

.mx-1 {
    margin-right: 1rem;
    margin-left: 1rem;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.m-0 {
    margin: 0;
}

.m-3 {
    margin: 3rem;
}

.p-0 {
    padding: 0;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 1rem !important;
}

.pl-2 {
    padding-left: 2rem !important;
}

.p-1 {
    padding: 1rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pr-2 {
    padding-right: 2rem !important;
}

.pb-1 {
    padding-bottom: 1rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.w-100 {
    width: 100%;
}

.vh-70 {
    height: 70vh !important;
}

.maw-50 {
    max-width: 50px !important;
}

.maw-70 {
    max-width: 70px !important;
}

.maw-100 {
    max-width: 100px !important;
}

.maw-150 {
    max-width: 150px !important;
}

.container {
    min-width: 950px;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.noUpper {
    text-transform: capitalize !important;
}

.text-lower {
    text-transform: none !important;
}

.sb {
    margin-left: -5px;
    width: 100%;
    text-overflow: ellipsis;
}
.edit-dis input[type="text"]:not(.browser-default):disabled {
    color: black;
    border-bottom: none;
    margin-bottom: 0;
}

.stat-dis input[type="text"]:not(.browser-default):disabled {
    color: black;
    border-bottom: 1px solid black;
}

/*input.select-dropdown {*/
    /*color: #ffffff;*/
/*}*/

.select-wrapper input.select-dropdown,
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default) {
    height: 2.5rem;
    line-height: 2.5rem;
}

textarea.materialize-textarea {
    min-height: 2.5rem;
}

.clear-row {
    display: flex;
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*align-items: stretch;*/
    /*flex-wrap: wrap;*/
    /*align-items: center;*/
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.align-stretch {
    align-items: stretch;
}

.align-center {
    align-items: center;
}

.align-bottom {
    align-items: flex-end;
}

.align-top {
    align-items: flex-start;
}

.td-top {
    vertical-align: top;
}

.td-mid {
    vertical-align: middle;
}

/*@media (max-width: 1100px) {*/
    /*.clear-row {*/
        /*flex-direction: column;*/
    /*}*/
/*}*/

.clear-row::after {
    content: none;
    display: none;
}

.noColorNav {
    background-color: transparent;
    box-shadow: none;
}

.col-auto {
    display: block;
}

.overflowHidden {
    overflow: hidden !important;
}

nav ul a {
    border-radius: 5px;
    outline: none;
}

nav ul a:hover {
    background-color: #F0F3F9 !important;
    border-radius: 5px;
}

ul a {
    outline: none;
    color: black;
}

ul li {
    padding: 2px 5px;
}

ul li:hover,
.active-menu {
    background-color: #F0F3F9 !important;
    cursor: pointer;
    border-radius: 5px;
}

.text-gray * {
    /*color: rgba(0,0,0,0.6) !important;*/
    color: rgba(0,0,0,0.6);
}

.txt-gray {
    color: rgba(0,0,0,0.6) !important;
}

.txt-lightGray {
    color: #999999 !important;
}

.dropdown-content {
    min-width: 300px;
    border-radius: 5px;
    /*background-color: #484747;*/
}

.dropdown-content a {
    border-radius: 0;
}

.dropdown-content a:hover {
    border-radius: 0;
}

.carousel .carousel-item > img {
    width: 240%;
    margin-left: -65%;
    margin-top: -40%;
    max-height: 315px;
}

.carousel {
    height: 500px;
    /*border: 2px solid red;*/
}

.carousel-slider .carousel-item > img {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    max-height: 100%;
}

.carousel .indicators {
    background: rgba(0, 0, 0, 0.5);
}

.carousel-text {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 1000;
    color: black;
    text-shadow: 0 0 6px white;
}

@media (max-width: 600px) {
    .carousel .carousel-item > img {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        max-height: 115px;
    }

    .carousel {
        height: 300px;
    }

    .carousel-slider .carousel-item > img {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        max-height: 100%;
    }
}

.d-none {
    display: none !important;
}
.d-block {
    display: block !important;
}
.opacity-0 {
    opacity: 0;
    transition: 0.3s;
}

.opacity-1 {
    opacity: 1;
    transition: 0.3s;
}

.list1 {
    /*overflow: hidden;*/
    margin: 0 0 15px 0;
    max-height: 150px;
    transition: all .5s;
}

.list2 {
    /*overflow: hidden;*/
    margin: 0 0 15px 0 !important;
    max-height: 1500px !important;
    transition: all .5s !important;
}

.list1-close {
    /*overflow: hidden;*/
    margin: 0 0 0 -250px !important;
    max-height: 0 !important;
    width: 0 !important;
    transition: all .5s !important;
}

.btn {
    border-radius: 5px;
    box-shadow: none;
}

.select-wrapper input.select-dropdown {
    margin-bottom: 23px;
}

.bgRed input.select-dropdown,
.bgGreen input.select-dropdown,
.bgGray input.select-dropdown {
    color: white !important;
    height: 2rem !important;
    border-bottom: none;
    text-align: center;
    margin-bottom: 0;
}

/*.select-wrapper + label {*/
    /*top: -24px !important;*/
/*}*/

.bgRed {
    background-color: #cc3402 !important;
}

.bgGreen {
    background-color: #75b000 !important;
}

.bgGray {
    background-color: #a0a0a0 !important;
}

.bRadius-7 {
    border-radius: 7px !important;
    width: 150px;
}

.bRadius-7 .select-wrapper + label {
    top: -29px !important;
}

.active-hover {
    background-color: #F0F3F9 !important;
    border-radius: 5px;
}

.my:hover {
    background-color: #F0F3F9 !important;
    border-radius: 5px;
}

.fa {
    min-width: 24px !important;
}
.fa.clear-minWidth {
    min-width: 0 !important;
}
.fa.flow-icon {
    opacity: 0.5;
}
.fa.flow-icon:hover {
    opacity: 1;
}
tr:hover i.fa.fa-pencil-square-o {
    opacity: 1 !important;
}

tr:hover i.fa.fa-trash-o {
    opacity: 1 !important;
}

.bb-none {
    border-bottom: none !important;
}

.afterHeader {
    margin-top: 65px;
}

.mt-noHeader {
    margin-top: 32px !important;
}

.mt-noHeader h5 {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.blue.lighten-1 {
    background-color: #6F7BD7 !important;
}

textarea.materialize-textarea {
    height: 2.5rem;
    min-height: 2.5rem !important;
}

.sb-bolder {
    font-weight: 600;
    color: #4e525a;
}

.td-actions {
    min-width: 72px;
    max-width: 72px;
    width: 72px;
}

.td-date {
    min-width: 72px;
    max-width: 72px;
    width: 72px;
}

.td-cOn {
    min-width: 110px;
}
.td-cBy {
    min-width: 147px;
}
.td-titleMAW {
    max-width: 150px;
    width: 150px;
    word-wrap: break-word;
}
.td-titleMAW-250 {
    max-width: 250px;
    width: 250px;
    word-wrap: break-word;
}
.td-titleMAW-350 {
    max-width: 350px;
    width: 350px;
    word-wrap: break-word;
}

.mb0 .select-wrapper input.select-dropdown {
    margin-bottom: 0;
    border-bottom: none;
}

.mb0-underlined .select-wrapper input.select-dropdown {
    margin-bottom: 0;
}

ul li.noHover:hover {
    background-color: #fff !important;
}

tr.nohover:hover {
    background-color: #fff !important;
}

.w-600 {
    max-width: 600px;
}

.h-70p {
    height: 70% !important;
}

.h-100p {
    height: 100% !important;
}

.onHover:hover {
    box-shadow: 1px 1px 2px #e3e3e3;
}

/*Epic block in roadmap*/
.blockWrapper {
    margin: 5px 0;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #dadada;
    box-shadow: 2px 2px 2px #dcdcdc;
    width: 100%;
}

.team {
    position: relative;
    padding-left: 2rem !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.team:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 10%;
    left: 0;
    border-left: 1px solid #eff1f4;
}

.clearTable tr {
    border: none;
    min-height: 40px;
    height: 40px;
}

.clearTable td,
.clearTable th {
    padding: 2px 15px;
}

.clearTable thead tr {
    border-bottom: 1px solid #eff1f4;
}

.dropdown-content {
    overflow-y: auto;
    max-height: 150px;
    min-width: 250px;
}