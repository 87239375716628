@import '~materialize-css/dist/css/materialize.min.css';
@import './css/materialize-extender.css';
@import './css/plate.css';
@import './css/colorBar.css';
@import './flowy/flowy.css';

body {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	position: relative;
	overflow-x: hidden;
}

ul.marked {
	margin-left: 20px;
}

ul.marked li {
	list-style: circle !important;
}

p {
	padding: 0;
	margin: 0;
}

.justified p {
	text-indent: 20px;
	text-align: justify;
}

p.redB b {
	color: #ee0000;
}

a {
	outline: none;
}

.modal-fixed-height {
	height: 605px !important;
	overflow-y: auto;
	overflow-x: hidden;
}

.btn {
  height: 32px !important;
  /*display: flex !important;*/
  flex-direction: row !important;    
  justify-content: center !important; /* Горизонтальное выравнивание */
  align-items: center !important;     /* Вертикальное выравнивание */
}

